import React, { useContext } from 'react';

import { DoorsContext } from '../../../context/DoorsContext';

export default function OrderDetails() {
    const { doorOrder } = useContext(DoorsContext);
    const formattedDate = doorOrder.orderDate != '' ? new Date(doorOrder.orderDate).toLocaleDateString('it-IT') : 'n/a';
    const doorsNumber = doorOrder.doors.reduce((accumulator, item) => Number(accumulator) + Number(item.qty), 0);

    return (
        <div className='order-details hidden-on-web'>
            <h3>DETTAGLI ORDINE</h3>
            <p className='info-item f5'>
                CLIENTE: <span className='font-bold'>{doorOrder.client != '' ? doorOrder.client : 'n/a'}</span>
            </p>
            <p className='info-item f5'>
                DATA ORDINE: <span className='font-bold'>{formattedDate}</span>
            </p>
            <p className='info-item f5'>
                SETTIMANA DI CONSEGNA: <span className='font-bold'>{doorOrder.orderDeliveryWeek != '' ? doorOrder.orderDeliveryWeek : 'n/a'}</span>
            </p>
            <p className='info-item f5'>
                {`TIPO PORTA: `} 
                <span className='font-bold'>
                    {doorOrder.doorType} 
                    {doorOrder.battenteType && ` ${doorOrder.battenteType}`} 
                    {doorOrder.antaLength && ` (${doorOrder.antaLength} cm)`} 
                    {doorOrder.slideType && ` ${doorOrder.slideType}`} 
                    {doorOrder.saloonType && ` ${doorOrder.saloonType}`} 
                    {doorOrder.doorSubtype && ` / ${doorOrder.doorSubtype}`} 
                </span>
            </p>
            <p className='info-item f5'>
                TOTALE PORTE: <span className='font-bold'>{doorsNumber}</span>
            </p>
            <p className='info-item f5'>
                COLORE PORTA: <span className='font-bold'>{doorOrder.doorColor != '' ? doorOrder.doorColor : 'n/a'}</span>
            </p>
            <p className='info-item f5'>
                COLORE CERNIERA: <span className='font-bold'>{doorOrder.hingeColor != '' ? doorOrder.hingeColor : 'n/a'}</span>
            </p>
            <p className='info-item f5'>
                ALLARGAMENTO CASSA: <span className='font-bold'>{doorOrder.caseEnlargement != '' ? doorOrder.caseEnlargement : 'n/a'}</span>
            </p>
            <p className='info-item f5'>
                NUMERO DI GIRI: <span className='font-bold'>{doorOrder.turnsNumber != '' ? doorOrder.turnsNumber : 'n/a'} </span><br/>
                <span>{doorOrder.turnsNumberNotes != '' ? doorOrder.turnsNumberNotes : ''}</span>
            </p>
            <p className='info-item f5'>
                COPRIFILO: <span className='font-bold'>{doorOrder.coprifilo != '' ? doorOrder.coprifilo : 'n/a'}</span>
            </p>
            <p className='info-item f5'>
                FINESTRATURA: <span className='font-bold'>{doorOrder.fenestration != '' ? doorOrder.fenestration : 'n/a'}</span>
            </p>
            {doorOrder.fenestration === 'Si' &&
                <p className='info-item f5'>
                    TIPO FINESTRATURA: <span className='font-bold'>{doorOrder.fenestrationType != '' ? doorOrder.fenestrationType : 'n/a'}</span>
                </p>
            }
            <p className='info-item f5'>
                GRIGLIA: <span className='font-bold'>{doorOrder.grid != '' ? doorOrder.grid : 'n/a'}</span>
            </p>
            {doorOrder.grid === 'Si' &&
                <p className='info-item f5'>
                    TIPO GRIGLIA: <span className='font-bold'>{doorOrder.gridType != '' ? doorOrder.gridType : 'n/a'}</span>
                </p>
            }
            <p className='info-item f5'>
                LUCE INFERIORE: <span className='font-bold'>{doorOrder.bottomLight != '' ? doorOrder.bottomLight : 'n/a'}</span>
            </p>
            <p className='info-item f5'>
                TIPO MANIGLIA: <span className='font-bold'>{doorOrder.doorHandleType != '' ? doorOrder.doorHandleType : 'n/a'}</span>
            </p>
            <p className='info-item f5'>
                COLORE MANIGLIA: <span className='font-bold'>{doorOrder.doorHandleColor != '' ? doorOrder.doorHandleColor : 'n/a'}</span>
            </p>
            {doorOrder.doorHandleNotes && doorOrder.doorHandleNotes != '' &&
                <p className='info-item f5'>
                    NOTE MANIGLIA: <span className='font-bold'>{doorOrder.doorHandleNotes}</span>
                </p>
            }
        </div>
    );
}