import React, { useContext } from 'react';

import { DoorsContext } from '../../../../context/DoorsContext';

export default function BattenteCutListTable() {
    const { doorOrder } = useContext(DoorsContext);

    return (
        doorOrder.doors.map((door, i) => {
            let profiliBattenteHeight = door.heightWallHole - 2.9;
            let panelHeight = door.heightWallHole - 5.5;
            if(doorOrder.bottomLight === '5cm') {
                profiliBattenteHeight = profiliBattenteHeight - 4.5;
                panelHeight = panelHeight - 4.5;
            } else if(doorOrder.bottomLight === '10cm') {
                profiliBattenteHeight = profiliBattenteHeight - 9.5;
                panelHeight = panelHeight - 9.5;
            }

            return <div key={i} className='doors-table mt-32'>
                <p>{door.qty} Porte Battente - {door.lengthWallHole}cm x {door.heightWallHole}cm - Apertura {door.opening}</p>
                <div className='header font-bold'>
                    <p>N° Pezzi</p>
                    <p>Articolo</p>
                    <p>Larghezza</p>
                    <p>Altezza</p>
                </div>
                <div className='table-content'>
                    <div className='row'>
                        <p>{door.qty}</p>
                        <p>Pannelli</p>
                        <p>{door.lengthWallHole - 9.2}cm</p>
                        <p>{panelHeight}cm</p>
                    </div>
                    <div className='row'>
                        <p>{door.qty * 2}</p>
                        <p>Profili Imbotte (45/90)</p>
                        <p></p>
                        <p>{door.heightWallHole - 1}cm</p> 
                    </div>
                    <div className='row'>
                        <p>{door.qty}</p>
                        <p>Profili Imbotte (45/45)</p>
                        <p>{door.lengthWallHole - 2}cm</p>
                        <p></p>
                    </div>
                    <div className='row'>
                        <p>{door.qty * 2}</p>
                        <p>Rinforzi per Imbotte (90/90)</p>
                        <p></p>
                        <p>{door.heightWallHole - 12.5}cm</p>
                    </div>
                    <div className='row'>
                        <p>{door.qty * 2}</p>
                        <p>Profili battente (45/45)</p>
                        <p></p>
                        <p>{profiliBattenteHeight}cm</p>
                    </div>
                    <div className='row'>
                        <p>{door.qty}</p>
                        <p>Profili battente (45/45)</p>
                        <p>{door.lengthWallHole - 6.5}cm</p>
                        <p></p>
                    </div>
                    <div className='row'>
                        <p>{door.qty}</p>
                        <p>Profilo C (45/45)</p>
                        <p>{door.lengthWallHole - 8.5}cm</p>
                        <p></p>
                    </div>
                    <div className='row'>
                        <p>{door.qty}</p>
                        <p>Kit profilo coprifilo</p>
                        <p></p>
                        <p></p>
                    </div>
                    {doorOrder.caseEnlargement === 'Si' &&
                        <>
                            <div className='row'>
                                <p>{door.qty * 2}</p>
                                <p>Profili allargamento cassa</p>
                                <p></p>
                                <p>{door.heightWallHole - 1}cm</p>
                            </div>
                            <div className='row'>
                                <p>{door.qty}</p>
                                <p>Profili allargamento cassa</p>
                                <p>{door.lengthWallHole - 2}cm</p>
                                <p></p>
                            </div>
                        </>
                    }
                </div>
            </div>
        })
    );
}