import React, { useState } from 'react';

import ClientDetailsStep from './ClientDetailsStep/ClientDetailsStep';
import DoorDetailsStep from './DoorDetailsStep/DoorDetailsStep';
import DoorHandleStep from './DoorHandleStep/DoorHandleStep';
import DoorSizeStep from './DoorSizeStep/DoorSizeStep';
import DoorCutListStep from './DoorCutListStep/DoorCutListStep';

export default function DoorsOrderConfigurator() {
    const [currentStep, setCurrentStep] = useState(0);

    return (
        <div className='order-configurator'>
            <p className='f2 font-bold'>
                Configuratore Ordine
            </p>
            <div className='stepper'>
                {currentStep === 0 &&
                    <ClientDetailsStep 
                        onPrimaryButtonClick={() => setCurrentStep(1)}
                    />
                }
                {currentStep === 1 &&
                    <DoorDetailsStep 
                        onSecondaryButtonClick={() => setCurrentStep(0)}
                        onPrimaryButtonClick={() => setCurrentStep(2)}
                    />
                }
                {currentStep === 2 &&
                    <DoorHandleStep 
                        onSecondaryButtonClick={() => setCurrentStep(1)}
                        onPrimaryButtonClick={() => setCurrentStep(3)}
                    />
                }
                {currentStep === 3 &&
                    <DoorSizeStep 
                        onSecondaryButtonClick={() => setCurrentStep(2)}
                        onPrimaryButtonClick={() => setCurrentStep(4)}
                    />
                }
                {currentStep === 4 &&
                    <DoorCutListStep 
                        onSecondaryButtonClick={() => setCurrentStep(3)}
                    />
                }
            </div>
        </div>
    );
}

