import React, { useContext, useState } from 'react';
import html2pdf from 'html2pdf.js';
import { PDFDownloadLink } from '@react-pdf/renderer';

import { DoorsContext } from '../../../context/DoorsContext';
import BattenteCutListTable from './CutListTables/BattenteCutListTable';
import DoppiaAntaBattenteCutListTable from './CutListTables/DoppiaAntaBattenteCutListTable';
import ScorrevoleEsternaCutListTable from './CutListTables/ScorrevoleEsternaCutListTable';
import ScorrevoleInternaCutListTable from './CutListTables/ScorrevoleInternaCutListTable';
import SaloonCutListTable from './CutListTables/SaloonCutListTable';
import SaloonDoppiaCutListTable from './CutListTables/SaloonDoppiaCutListTable';
import OrderDetails from './OrderDetails';
import './DoorCutListStep.scss';

export default function DoorCutListStep({ onSecondaryButtonClick }) {
    const { doorOrder } = useContext(DoorsContext);
    const formattedDate = doorOrder.orderDate != '' ? new Date(doorOrder.orderDate).toLocaleDateString('it-IT') : 'n/a';

    const generatePDF = () => {
        const element = document.getElementById('door-cut-list-step');

        const hiddenElements = document.getElementsByClassName('hidden-on-web');
        Array.from(hiddenElements).forEach(el => {
            el.style.display = 'block';
        });

        const options = {
            margin: [10, 10, 20, 10],
            filename: 'lista_taglio.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
        };

        html2pdf()
            .set(options)
            .from(element)
            .save()
            .then(() => {
                Array.from(hiddenElements).forEach(el => {
                    el.style.display = 'none';
                });
            });
    };

    return (
        <>
            <div className='step door-cut-list-step' id="door-cut-list-step">
                <OrderDetails />
                {/* Interruzione di pagina */}
                <div style={{ pageBreakBefore: 'always' }}></div>
                <p className='f3 font-bold mt-0'>LISTA TAGLIO</p>
                {doorOrder.doorType === 'Battente' && doorOrder.battenteType === 'Anta Singola' &&
                    <BattenteCutListTable />
                }
                {doorOrder.doorType === 'Battente' && doorOrder.battenteType === 'Anta Doppia' &&
                    <DoppiaAntaBattenteCutListTable />
                }
                {doorOrder.doorType === 'Scorrevole' && doorOrder.slideType === 'Esterna' &&
                    <ScorrevoleEsternaCutListTable />
                }
                {doorOrder.doorType === 'Scorrevole' && doorOrder.slideType === 'Interno Muro' &&
                    <ScorrevoleInternaCutListTable />
                }
                {doorOrder.doorType === 'Saloon' && doorOrder.saloonType === 'Singola' &&
                    <SaloonCutListTable />
                }
                {doorOrder.doorType === 'Saloon' && doorOrder.saloonType === 'Doppia' &&
                    <SaloonDoppiaCutListTable />
                }
            </div>
            <div className='buttons mt-32 d-flex'>
                <button
                    className="secondary-button"
                    onClick={onSecondaryButtonClick}
                >
                    Indietro
                </button>
                <button
                    className="primary-button"
                    onClick={generatePDF}
                    // disabled={true}
                >
                    Esporta PDF
                </button>
                {/* <PDFDownloadLink document={<DoorCutListStepPdfDocument />} fileName="document.pdf">
                <button
                    className="primary-button"
                    onClick={() => { }}
                    // disabled={true}
                >
                    Esporta PDF
                </button>
            </PDFDownloadLink> */}
            </div>
        </>
    );
}