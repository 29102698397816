export const doorTypes = [
    { value: 'Battente', label: 'Battente' },
    { value: 'Scorrevole', label: 'Scorrevole' },
    { value: 'Saloon', label: 'Saloon' },
]

export const doorSubtypes = [
    { value: 'Laminata', label: 'Laminata' },
    { value: 'Dogata', label: 'Dogata' },
]

export const battenteTypes = [
    { value: 'Anta Singola', label: 'Anta Singola' },
    { value: 'Anta Doppia', label: 'Anta Doppia' },
]

export const slidingTypes = [
    { value: 'Interno Muro', label: 'Interno Muro' },
    { value: 'Esterna', label: 'Esterna' },
]

export const saloonTypes = [
    { value: 'Singola', label: 'Singola' },
    { value: 'Doppia', label: 'Doppia' },
]

export const doorColors = [
    { value: '#fff', label: 'Bianco' },
    { value: '#c6c8ca', label: 'Grigio' },
    { value: '#f0e0c8', label: 'Avorio' },
]

export const hingeColors = [
    { value: '#fff', label: 'Bianco' },
    { value: '#000', label: 'Nero' },
    { value: '#c6c8ca', label: 'Argento' },
]

export const yesNoOptions = [
    { value: 'Si', label: 'Si' },
    { value: 'No', label: 'No' },
]

export const gridTypes = [
    { value: 'Singola Alta', label: 'Singola Alta' },
    { value: 'Singola Bassa', label: 'Singola Bassa' },
    { value: 'Doppia Alta', label: 'Doppia Alta' },
    { value: 'Doppia Bassa', label: 'Doppia Bassa' },
]

export const fenestrationTypes = [
    { value: 'Alta', label: 'Alta' },
    { value: 'Mezza Porta', label: 'Mezza Porta' },
    { value: 'Porta Intera', label: 'Porta Intera' },
]

export const openingTypes = [
    { value: 'Sinistra', label: 'Sinistra' },
    { value: 'Destra', label: 'Destra' },
]

export const lightOptions = [
    { value: 'No', label: 'No' },
    { value: '5cm', label: '5cm' },
    { value: '10cm', label: '10cm' },
]