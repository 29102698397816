import React, { useContext } from 'react';

import { DoorsContext } from '../../../context/DoorsContext';

export default function DoorHandleStep({ onPrimaryButtonClick, onSecondaryButtonClick }) {
    const { doorOrder, setDoorOrder } = useContext(DoorsContext);

    return (
        <div className='step door-detail-step'>
            <p className='f3 font-bold mt-0'>Dettagli Maniglia</p>
            <div className='input-wrapper'>
                <label>Tipo maniglia:</label>
                <input
                    autoComplete="off"
                    name="doorHandleType"
                    className="input"
                    value={doorOrder.doorHandleType}
                    onChange={(e) => setDoorOrder(prevOrder => ({ ...prevOrder, doorHandleType: e.target.value }))}
                    type="text"
                    placeholder="Tipo maniglia"
                />
            </div>
            <div className='input-wrapper mt-32'>
                <label>Colore maniglia:</label>
                <input
                    autoComplete="off"
                    name="doorHandleColor"
                    className="input"
                    value={doorOrder.doorHandleColor}
                    onChange={(e) => setDoorOrder(prevOrder => ({ ...prevOrder, doorHandleColor: e.target.value }))}
                    type="text"
                    placeholder="Colore maniglia"
                />
            </div>
            <div className='input-wrapper mt-32'>
                <label>Note:</label>
                <textarea
                    name="doorHandleNotes"
                    className="input"
                    value={doorOrder.doorHandleNotes}
                    onChange={(e) => setDoorOrder(prevOrder => ({ ...prevOrder, doorHandleNotes: e.target.value }))}
                    placeholder="Note"
                    rows={4}
                ></textarea>
            </div>
            <div className='buttons mt-32 d-flex'>
                <button
                    className="secondary-button"
                    onClick={onSecondaryButtonClick}
                >
                    Indietro
                </button>
                <button
                    className="primary-button"
                    onClick={onPrimaryButtonClick}
                    disabled={false}
                >
                    Avanti
                </button>
            </div>
        </div>
    );
}

