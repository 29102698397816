import React, { useContext } from 'react';
import { DoorsContext } from '../../../context/DoorsContext';

export default function ClientDetailsStep({ onPrimaryButtonClick }) {
    const { doorOrder, setDoorOrder } = useContext(DoorsContext);

    const isNextButtonDisabled = () => {
        return doorOrder.client === '' || doorOrder.orderDate === '' || doorOrder.orderDeliveryWeek === '';
    }

    return (
        <div className='step client-details-step'>
            <p className='f3 font-bold mt-0'>Dettagli Cliente</p>
            <div className='d-flex gap-24'>
                <div className='input-wrapper'>
                    <label>Nome Azienda</label>
                    <input
                        autoComplete="off"
                        name="client"
                        className="input"
                        value={doorOrder.client}
                        onChange={(e) => setDoorOrder(prevOrder => ({ ...prevOrder, client: e.target.value }))}
                        type="text"
                        placeholder="Nome Azienda"
                    />
                </div>
                <div className='input-wrapper'>
                    <label>Commessa</label>
                    <input
                        autoComplete="off"
                        name="sale"
                        className="input"
                        value={doorOrder.sale}
                        onChange={(e) => setDoorOrder(prevOrder => ({ ...prevOrder, sale: e.target.value }))}
                        type="text"
                        placeholder="Commessa"
                    />
                </div>
            </div>
            <div className='d-flex gap-24'>
                <div className='input-wrapper'>
                    <label>Data Ordine</label>
                    <input
                        autoComplete="off"
                        name="orderDate"
                        className="input"
                        value={doorOrder.orderDate}
                        onChange={(e) => setDoorOrder(prevOrder => ({ ...prevOrder, orderDate: e.target.value }))}
                        type="date"
                        placeholder="Nome Azienda"
                    />
                </div>
                <div className='input-wrapper'>
                    <label>Settimana di consegna</label>
                    <input
                        autoComplete="off"
                        name="orderDeliveryWeek"
                        className="input"
                        value={doorOrder.orderDeliveryWeek}
                        onChange={(e) => setDoorOrder(prevOrder => ({ ...prevOrder, orderDeliveryWeek: e.target.value }))}
                        type="number"
                        placeholder="Settimana di consegna"
                    />
                </div>
            </div>
            <div className='buttons mt-32 d-flex'>
                <button
                    className="primary-button"
                    onClick={onPrimaryButtonClick}
                    // disabled={isNextButtonDisabled()}
                >
                    Avanti
                </button>
            </div>
        </div>
    );
}

