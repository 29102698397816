import React, { useContext, useState } from 'react';

import { DoorsContext } from '../../../context/DoorsContext';
import AddDoorModal from '../AddDoorModal/AddDoorModal';
import './DoorSizeStep.scss';

export default function DoorSizeStep({ onPrimaryButtonClick, onSecondaryButtonClick }) {
    const { doorOrder, setDoorOrder } = useContext(DoorsContext);
    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleAddDoor = (door) => {
        setDoorOrder(prevOrder => ({
            ...prevOrder,
            doors: [...prevOrder.doors, door]
        }));
        setShowModal(false);
    };

    const handleDeleteDoor = (doorToDelete) => {
        setDoorOrder(prevOrder => ({
            ...prevOrder,
            doors: prevOrder.doors.filter(door => door !== doorToDelete)
        }));
    };

    return (
        <div className='step door-size-step'>
            <div className='step-header d-flex align-items-center justify-content-between'>
                <p className='f3 font-bold mt-0 mb-0'>Misure e numero porte</p>
                <button
                    className="primary-button"
                    onClick={toggleModal}
                >
                    Aggiungi
                </button>
            </div>
            <div className='doors-table mt-32'>
                <div className='header font-bold'>
                    <p>Quantità</p>
                    <p>Apertura</p>
                    <p>Foro Muro (L x A)</p>
                    <p>Utile Passaggio (L x A)</p>
                    <p className='actions'></p>
                </div>
                <div className='table-content'>
                    {doorOrder.doors.map((door, i) =>
                        <div key={i} className='row'>
                            <p>{door.qty}</p>
                            <p>{door.opening}</p>
                            <p>{door.lengthWallHole}cm x {door.heightWallHole}cm</p>
                            <p>
                                {door.lengthRide && door.heightRide ?
                                    <span>{door.lengthRide}cm x {door.heightRide}cm</span> :
                                    ''
                                }
                            </p>
                            <p className='actions font-bold' onClick={() => handleDeleteDoor(door)}>X</p>
                        </div>
                    )}
                </div>
            </div>
            <AddDoorModal 
                showModal={showModal}
                onPrimaryButtonClick={(door) => handleAddDoor(door)}
                onClose={toggleModal}
            /> 
            <div className='buttons mt-32 d-flex'>
                <button
                    className="secondary-button"
                    onClick={onSecondaryButtonClick}
                >
                    Indietro
                </button>
                <button
                    className="primary-button"
                    onClick={onPrimaryButtonClick}
                    disabled={!doorOrder.doors.length}
                >
                    Lista Taglio
                </button>
            </div>
        </div>
    );
}