import React, { useContext, useState } from 'react';

import Modal from '../../../components/Modal/Modal';
import { openingTypes } from '../../../constants/constants';
import { DoorsContext } from '../../../context/DoorsContext';

const initialState = {
    qty: '',
    opening: '',
    lengthWallHole: '',
    heightWallHole: '',
    lengthRide: '',
    heightRide: '',
}

export default function AddDoorModal({ showModal, onPrimaryButtonClick, onClose }) {
    const { doorOrder } = useContext(DoorsContext);
    const [door, setDoor] = useState(initialState);

    const handleDoorTypeSelect = (openingType) => {
        setDoor({ ...door, opening: openingType.label });
    };

    const isPrimaryButtonDisabled = () => {
        if(
            door.qty === '' ||
            door.opening === '' ||
            door.lengthWallHole === '' ||
            door.heightWallHole === ''
        ) {
            return true;
        }
        return false;
    };

    return (
        <Modal show={showModal} onClose={onClose} title="Aggiungi porte">
            <div className='input-wrapper'>
                <label>Quantità</label>
                <input
                    autoComplete="off"
                    name="doorQty"
                    className="input"
                    value={door.qty}
                    onChange={(e) => setDoor({ ...door, qty: e.target.value })}
                    type="number"
                    placeholder="Numero di porte"
                />
            </div>
            <div className='door-opening mt-32 d-flex gap-24'>
                <p className='f4'>Apertura:</p>
                <div className='d-flex gap-24'>
                    {openingTypes.map((openingType, i) =>
                        <div key={i} className={`select-card ${door.opening === openingType.value ? 'selected' : ''}`} onClick={() => handleDoorTypeSelect(openingType)}>
                            <p>{openingType.label}</p>
                        </div>
                    )}
                </div>
            </div>
            <div className='mt-32'>
                <p className='f4'>Misura foro muro:</p>
                <div className='d-flex gap-24 align-items-center'>
                    <div className='input-wrapper'>
                        <label>Larghezza (in cm)</label>
                        <input
                            autoComplete="off"
                            name="length"
                            className="input"
                            value={door.lengthWallHole || ''}
                            onChange={(e) => {
                                const lengthValue = Number(e.target.value); 
                                setDoor({
                                    ...door,
                                    lengthWallHole: lengthValue,
                                    // lengthRide: lengthValue - 8
                                });
                            }}
                            type="number"
                            placeholder="Larghezza"
                        />
                    </div>
                    <p className='f3'>X</p>
                    <div className='input-wrapper'>
                        <label>Altezza (in cm)</label>
                        <input
                            autoComplete="off"
                            name="height"
                            className="input"
                            value={door.heightWallHole || ''}
                            onChange={(e) => {
                                const heightValue = Number(e.target.value); 
                                setDoor({
                                    ...door,
                                    heightWallHole: heightValue,
                                    // heightRide: heightValue - 4
                                });
                            }}
                            type="number"
                            placeholder="Altezza"
                        />
                    </div>
                </div>
            </div>
            {/* {doorOrder.doorType === 'Battente' && doorOrder.battenteType === 'Anta Doppia' &&
                <div className='mt-0'>
                    <p className='f4'>Larghezza anta piccola (in cm):</p>
                    <div className='input-wrapper'>
                        <input
                            autoComplete="off"
                            name="length"
                            className="input"
                            value={door.smallDoorValue || ''}
                            onChange={(e) => {
                                const smallDoorValue = Number(e.target.value); 
                                setDoor({
                                    ...door,
                                    smallDoorValue: smallDoorValue,
                                });
                            }}
                            type="number"
                            placeholder="Larghezza"
                        />
                    </div>
                </div>
            } */}
            <div className='mt-32'>
                <p className='f4'>Misura utile passaggio:</p>
                <div className='d-flex gap-24 align-items-center'>
                    <div className='input-wrapper'>
                        <label>Larghezza (in cm)</label>
                        <input
                            autoComplete="off"
                            name="length"
                            className="input"
                            value={door.lengthRide || ''}
                            onChange={(e) => {
                                const lengthValue = Number(e.target.value); 
                                setDoor({
                                    ...door,
                                    lengthRide: lengthValue,
                                    lengthWallHole: lengthValue + 10
                                });
                            }}
                            type="number"
                            placeholder="Larghezza"
                        />
                    </div>
                    <p className='f3'>X</p>
                    <div className='input-wrapper'>
                        <label>Altezza (in cm)</label>
                        <input
                            autoComplete="off"
                            name="height"
                            className="input"
                            value={door.heightRide || ''}
                            onChange={(e) => {
                                const heightValue = Number(e.target.value); 
                                setDoor({
                                    ...door,
                                    heightRide: heightValue,
                                    heightWallHole: heightValue + 5
                                });
                            }}
                            type="number"
                            placeholder="Altezza"
                        />
                    </div>
                </div>
            </div>
            <div className='mt-32 d-flex'>
                <button
                    className="primary-button ml-auto"
                    disabled={isPrimaryButtonDisabled()}
                    onClick={() => {
                        onPrimaryButtonClick(door);
                        setDoor(initialState);
                    }}
                >
                    Aggiungi
                </button>
            </div>
        </Modal>
    );
}