import React, { createContext, useState } from 'react';

export const DoorsContext = createContext();

export const DoorsProvider = ({ children }) => {
    const [doorOrder, setDoorOrder] = useState({
        antaLength: '',
        caseEnlargement: 'No',
        client: '',
        sale: '',
        coprifilo: 'No',
        orderDate: '',
        orderDeliveryWeek: '',
        doorColor: '',
        doorType: '',
        doorSubtype: '',
        doorQtyDx: '',
        doorQtySx: '',
        doorHandleType: '',
        doorHandleColor: '',
        doorHandleNotes: '',
        doorHeight: '',
        doorLength: '',
        hingeColor: '',
        fenestration: 'No',
        fenestrationType: '',
        grid: 'No',
        gridType: '',
        battenteType: '',
        slideType: '',
        saloonType: '',
        turnsNumber: '',
        turnsNumberNotes: '',
        bottomLight: 'No',
        doors: [],
        cutList: {},
    });

    return (
        <DoorsContext.Provider value={{ doorOrder, setDoorOrder }}>
            {children}
        </DoorsContext.Provider>
    );
};